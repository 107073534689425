import React, { useState, useEffect, useContext } from "react";
import { AiOutlineSolution } from "react-icons/ai";
import jwt_decode from "jwt-decode";
import LoginService from "../../services/LoginService";
import CourseService from "../../services/CourseService";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import "./navbar.scss";
import img1 from "../../assets/images/profile.svg";
import img2 from "../../assets/images/search.svg";
import img3 from "../../assets/images/my-learning.svg";
import img4 from "../../assets/images/logout.svg";
import img5 from "../../assets/images/search-black.svg";
import img6 from "../../assets/images/login.svg";
import img7 from "../../assets/images/signup.svg";
import img9 from "../../assets/images/calendar.svg";
import img10 from "../../assets/images/profile.svg";
import img11 from "../../assets/images/exam.svg";
import { UserContext } from "../../routing/Routing";

export default function Navbar() {
  const { loginStatus, setLoginStatus, setView } = useContext(UserContext);

  const [user, setUser] = useState();
  const [category, setCategory] = useState([]);
  const [batch, setBatch] = useState([]);
  const [tpdf, setTPDF] = useState([]);
  // var [userdata, setUserdata] = useState(0);
  const [allcourse, setAllCourse] = useState([""]);
  const [allsubcourse, setAllSubCourse] = useState([""]);
  const navigate = useNavigate();
  const [logoutToggle, setLogoutToggle] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [snav, setSnav] = useState("home");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchcourse, setSearchCourse] = useState("");
  const [searchView, setSearchView] = useState("");
  const [togglebtn, setTogglebtn] = useState(false);
  const token = localStorage.getItem("token");

  if (!token) {
    setLoginStatus(false)
  }

  useEffect(() => {
    CourseService.getCourses()
      .then((res) => {
        setAllCourse(res.data);
      })
      .catch((ex) => console.log(ex));
    CourseService.getSubCourses()
      .then((res) => {
        setAllSubCourse(res.data);
      })
      .catch((ex) => console.log(ex));
    CourseService.getJson()
      .then((res) => {
        console.log(res.data);
      })
      .catch((ex) => console.log(ex));
    $(document).ready(function () {
      $(document).click(function (event) {
        var clickover = $(event.target);
        if (clickover.hasClass("navbar-toggler")) {
          console.log("Test");
        }
        if (
          !clickover.hasClass("navbar-toggler") &&
          !clickover.hasClass("snav") &&
          $(window).width() <= 1199
        ) {
          $("div.navbar-collapse").removeClass("show");
          $("div.megamenu").addClass("show");
          //setSearchCourse("");
        }
        if (!clickover.hasClass("btn") && !clickover.hasClass("input")) {
          $("div.search-input").removeClass("show");
          //setSearchCourse("");
        }
      });
      footerShow();
      $(window).on("resize", function () {
        footerShow();
      });
    });
    function footerShow() {
      var screenWidth = $(document).width();
      if (screenWidth < 1199) {
        $("footer").css({ "margin-bottom": $(".home-footer").height() });
        $(".footer-sm").css("bottom", "0px");
        $(window).scroll(function () {
          let d = document.documentElement;
          let offset = d.scrollTop + window.innerHeight;
          let height = d.offsetHeight;
          if (offset >= height) {
          } else {
            $(".footer-sm").css("bottom", "0px");
          }
        });
      } else {
        $(".footer-sm").fadeOut();
        $("footer").css({ "margin-bottom": "0px" });
      }
    }
    const token = localStorage.getItem("token");
    if (
      !token &&
      window.location.href.split("/")[3] !== "login" &&
      window.location.href.split("/")[3] !== "signup"
    ) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
    if (token) {
      const decoded = jwt_decode(token);
      if (decoded) {
        LoginService.getUser(token)
          .then((res) => {
            if (res.data.msg) {
              setUser(decoded);
            } else {
              localStorage.removeItem("token");
              setLoginStatus(false);
              navigate("/login");
            }
          })
          .catch((ex) => {
            localStorage.removeItem("token");
            setLoginStatus(false);
            navigate("/login");
          });
      }
      // LoginService.getTrainers(token)
      //   .then((res) => {
      //     if (res.data.length > 0) {
      //       var reqs = 0;
      //       for (var i = 0; i < res.data.length; i++) {
      //         var user = res.data[i];
      //         if (user.subscription.length !== 0) {
      //           for (var j = 0; j < user.subscription.length; j++) {
      //             if (!user.subscription[j].Subscribe) {
      //               reqs += 1;
      //             }
      //           }
      //         }
      //       }
      //       setUserdata(reqs);
      //     }
      //   })
    } else {
      setLoginStatus(false);
    }
  }, [loginStatus, setView]);
  var el = document.getElementById("abcd");
  useEffect(() => {
    if (el) {
      if (el.ariaExpanded == "true") {
        document.body.classList.add("stickysearch");
        // $(document).ready(function () {
        // $(".togglemenu_icon").click(function() {
        $(".d-ipad .nav-item a").addClass("text-white");
        // });
        // })
      }
      if (el.ariaExpanded == "false") {
        document.body.classList.remove("stickysearch");
        // $("#abcd .btn-close").click(function() {
        $(".d-ipad .nav-item a").removeClass("text-white");
        navbarT();
        // });
      }
    }
    setTogglebtn(false);
  }, [togglebtn]);

  const navbarT = () => {
    $("button.navbar-toggler").addClass("collapsed");
    $("button.navbar-toggler").attr("aria-expanded", "false");
    setTogglebtn(true);
  };
  const onChange = (e) => {
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = allcourse.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(userInput.toLowerCase())
    );
    setSearchCourse(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const onClick = (e, name) => {
    if (searchView === "mobile") {
      navigate("/course/" + name);
      setFilteredSuggestions([]);
      setSearchCourse(name);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
      setShowSearch(!showSearch);
      document.body.classList.remove("stickysearch");
    } else {
      navigate("/course/" + name);
      setFilteredSuggestions([]);
      setSearchCourse(name);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    }
  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions &&
      filteredSuggestions.length &&
      filteredSuggestions.length > 0 ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className;
          // Flag the active suggestion with a className
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }
          return (
            <li
              className={className}
              key={suggestion}
              onClick={(e) => onClick(e, suggestion.name)}
            >
              {suggestion.name}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <p>No suggestions, you're on your own!</p>
      </div>
    );
  };

  const handleLogin = async (ch) => {
    if (ch === "login") {
      if (!loginStatus) {
        navigate({ pathname: "/login" });
        setLogoutToggle(false);
      }
    }
    if (ch === "logout") {
      if (loginStatus) {
        await LoginService.logout(token)
        localStorage.removeItem("token");
        setLoginStatus(false);
        setShowNavbar(false);
        window.location.href = "/login";
      }
    }
    if (logoutToggle === true) {
      setLogoutToggle(false);
    } else {
      setLogoutToggle(true);
    }
  };

  return (
    <>
      <header className="navbar">
        <nav className="navbar-expand-xl navbar-light header header-main">
          <div className="container-fluid p-0">
            <div className="row w-100 m-0 align-items-center">
              <div className="col-xl-4 col-12 d-flex justify-content-between align-items-center">
                <Link to="/" className="navbar-brand">
                  <img
                    src="https://zt-bucket.s3.ap-south-1.amazonaws.com/upskill_logo.png"
                    // src="/Zero_threat_Logo-198x78.svg"
                    alt="Upskill"
                    width="150"
                    height="60"
                  />

                </Link>
                <div className="input-group d-xl-flex d-none">
                  <input
                    type="text"
                    onChange={onChange}
                    placeholder="What you want to learn today?"
                    aria-describedby="button-search"
                    className="form-control search-input"
                    // onChange={(e) => {
                    //   setSearchCourse(e.target.value);
                    //   history.push("/", { search: e.target.value });
                    // }}

                    value={searchcourse}
                  ></input>
                  {showSuggestions && searchcourse && (
                    <SuggestionsListComponent />
                  )}
                  <div className="input-group-append btn-search">
                    <button id="button-search" type="submit" className="btn">
                      <img src={img2} alt="Search" height="18" width="18" />
                    </button>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="d-flex d-xl-none me-0 me-lg-3 align-items-center justify-content-center p-0">
                    <a
                      onClick={() => {
                        setShowSearch(!showSearch);
                      }}
                    >
                      <img
                        width="18"
                        height="18"
                        className="text-black"
                        src={img5}
                      />
                      {/* <span>Search</span> */}
                    </a>
                  </div>

                  <button
                    className={`navbar-toggler border-none mobile-toggle ms-3`}
                    type="button"
                    id="abcd"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_nav"
                    aria-controls="main_nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setTogglebtn(true)}
                  >
                    {el && el.ariaExpanded == "false" && (
                      <img
                        src={
                          require("../../assets/images/ionic-md-menu.svg")
                            .default
                        }
                        width="33"
                        height="24"
                        alt="menu bar"
                        className="togglemenu_icon"
                      />
                    )}
                    {el && el.ariaExpanded == "true" && (
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        aria-label="Close"
                      ></button>
                    )}
                  </button>
                  {!loginStatus ? (
                    <ul className="align-items-center list-unstyled mb-0 d-none d-xl-none d-lg-flex d-ipad">
                      <>
                        <li className="nav-item">
                          <Link
                            to="/login"
                            title="Login"
                            id="authlink"
                            className="ps-3 text-decoration-none text-primary"
                            onClick={(e) => {
                              if (el.ariaExpanded) {
                                navbarT();
                              }
                            }}
                          >
                            Login
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/signup"
                            title="Sign Up"
                            id="authlink"
                            className="ps-3 text-decoration-none text-primary"
                            onClick={(e) => {
                              if (el.ariaExpanded) {
                                navbarT();
                              }
                            }}
                          >
                            Sign Up
                          </Link>
                        </li>
                      </>
                    </ul>
                  ) : (
                    <ul className="align-items-center list-unstyled mb-0 d-xl-none d-lg-flex d-ipad">
                      <li>
                        <Link
                          className="nav-item ps-3 text-decoration-none text-primary"
                          title="Logout text-primary"
                          onClick={async () => await handleLogin("logout")}
                        >
                          <img
                            width="30"
                            height="30"
                            src={img4}
                            className="bg-primary p-2 rounded-3"
                          />
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div className="col-xl-8 col-12 mobile-navbar">
                <div
                  className="collapse navbar-collapse justify-content-end position-relative"
                  id="main_nav"
                >
                  <ul className="navbar-nav align-items-center">
                    <li ><Link to="/productlisting" className="text-dark text-decoration-none">Products</Link></li>
                    <li ><Link to="/access-keys" className="text-dark text-decoration-none mx-2"></Link></li>
                    <li className="ms-2"><Link className="text-dark text-decoration-none" to="/productcategory/list">Category</Link></li>
                    <li className="ms-2"><Link to="/contact-us" className="text-dark text-decoration-none">ContactUs</Link></li>
                    <li className="ms-2"><Link to="/managetextdata" className="text-dark text-decoration-none">Textdata</Link></li>
                    <li
                      className="nav-item dropdown has-megamenu w-100"
                      onClick={() => navbarT()}
                    >
                      <a
                        className="nav-link dropdown-toggle nav-hover-color text-dark d-xl-block d-none"
                        title="Available Courses"
                      // data-bs-toggle="dropdown"
                      >
                        Available Courses
                      </a>
                      <div
                        className={`megamenu ${token ? "after-login-megamenu" : ""
                          }`}
                        role="menu"
                        data-bs-popper="dropdown"
                      >
                        <div className="row">
                          {allcourse.map((course) => {
                            return (
                              <div className="col-megamenu col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                <Link
                                  className="title h6 font-weight-400 text-primary"
                                  to={`/course/${course.name}`}
                                >
                                  {course.name}
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                    {/* <li className="nav-item dropdown ms-4 w-100">
                      <a
                        className="nav-link text-dark nav-hover-color d-xl-block d-none"
                        // title="Available Courses"
                        // data-bs-toggle="dropdown"
                        href="/calendar"
                      >
                        Training Calendar
                      </a>
                    </li> */}

                    {!loginStatus ? (
                      <>
                        <li className="nav-item d-xl-block d-none">
                          <Link
                            to="/login"
                            title="Login" img4
                            className="btn btn-white ms-4"
                          >
                            Login
                          </Link>
                        </li>
                        <li className="nav-item d-xl-block d-none">
                          <Link
                            to="/signup"
                            title="Sign Up"
                            className="btn btn-blue ms-4"
                          >
                            Sign Up
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-item d-xl-block d-none">
                          <p className="ms-4 mb-0">Welcome</p>
                        </li>
                        <li className="nav-item dropdown profile-btn ms-3 d-xl-block d-none">
                          <a
                            title="Profile"
                            className="nav-link dropdown-toggle bg-blue"
                            data-bs-toggle="dropdown"
                          >
                            <span className="d-block me-3">
                              {
                                token && jwt_decode(localStorage.getItem("token"))
                                  .username
                              }
                            </span>
                          </a>
                          <ul className="dropdown-menu fade-up">
                            {token && jwt_decode(localStorage.getItem("token")).role ===
                              "Student" ? (
                              <>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/mylearning"
                                  >
                                    <img
                                      src={img3}
                                      alt=" My Learning"
                                      width="15"
                                      height="15"
                                    />
                                    My Learning
                                  </Link>
                                </li>
                                {/* <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/tracktechnology"
                                  >
                                    <img
                                      src={img3}
                                      alt="My Learning"
                                      width="15"
                                      height="15"
                                    />
                                    Trach Skills
                                  </Link>
                                </li> */}
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/testExam"
                                  >
                                    <img src={img11} width="15" height="15" />
                                    Mcq Exam
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/practicalExam"
                                  >
                                    <img src={img11} width="15" height="15" />
                                    Practical Exam
                                  </Link>
                                </li>
                                {/* <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/applyleave"
                                  >
                                    <img src={img11} width="15" height="15" />
                                    Leave
                                  </Link>
                                </li> */}
                                {/* <li>
                              <Link className="dropdown-item" to="/calendar">
                                <img
                                  src={img9}
                                  alt=" My Learning"
                                  width="15"
                                  height="15"
                                />
                                Training Calendar
                              </Link>
                            </li>*/}
                                <li>
                                  <Link className="dropdown-item" to="/profile">
                                    <img
                                      src={img10}
                                      alt=" My Learning"
                                      width="15"
                                      height="15"
                                    />
                                    My Profile
                                  </Link>
                                </li>
                              </>
                            ) : (
                              <></>
                            )}{" "}
                            {token && jwt_decode(localStorage.getItem("token")).role ===
                              "Student" ? (
                              <></>
                            ) : (
                              <>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/admin/dashboard"
                                  >
                                    <img
                                      src={img1}
                                      alt="Admin"
                                      width="15"
                                      height="15"
                                    />
                                    Admin
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/admin/course-list"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16" className="me-1">
                                      <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                    </svg>
                                    Course Llist
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/admin/student-list"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16" className="me-1">
                                      <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                    </svg>
                                    Student List
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="admin/Batch/list"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16" className="me-1">
                                      <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                                    </svg>
                                    Batch List
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="admin/documents"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16" className="me-1">
                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                      <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                    </svg>
                                    Upload Documents
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="/admin/contact-form"
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone me-1" viewBox="0 0 16 16">
                                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                    </svg>
                                    Report a Bug
                                  </Link>
                                </li>

                                {/* <li>
                                  <Link className="dropdown-item" to="/usersResults">
                                    <AiOutlineSolution className="me-1 fs-6" /> Result sheet
                                  </Link>
                                </li> */}
                              </>
                            )}
                            <li>
                              <Link className="dropdown-item" to="/calendar">
                                <img
                                  src={img9}
                                  alt=" My Learning"
                                  width="15"
                                  height="15"
                                />
                                Training Calendar
                              </Link>
                            </li>

                            <li>

                              <Link
                                className="dropdown-item"
                                onClick={async () => await handleLogin("logout")}
                              >
                                <img
                                  src={img4}
                                  alt="Logout"
                                  width="15"
                                  height="15"
                                />
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>

            </div>
            {/* <input type="search" className="form-control form-control-dark w-auto" placeholder="Search..." aria-label="Search"></input> */}
            <div > Test and Demostration site for ZeroThreat Scanner</div>
          </div>
        </nav>

      </header>
      {showSearch && (
        <div className="w-100 h-100 mobile-serach">
          <div className="text-end mb-2">
            {/* <img
              onClick={(e) => {
                setShowSearch(!showSearch);
                document.body.classList.remove("stickysearch");
              }}
              src={closeIcon}
              alt="closeButton"
              width="20"
              height="20"
            /> */}
            <button
              onClick={(e) => {
                setShowSearch(!showSearch);
                document.body.classList.remove("stickysearch");
              }}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <div>
            <input
              type="text"
              onChange={(e) => {
                setSearchView("mobile");
                onChange(e);
              }}
              placeholder="What you want to learn today?"
              aria-describedby="button-search"
              className="form-control search-input ps-1 font-14"
              value={searchcourse}
            ></input>
            {showSuggestions && searchcourse && <SuggestionsListComponent />}
          </div>
        </div>
      )}
      {loginStatus && !(window?.location?.pathname?.split("/").length > 5) && (
        <div className="footer-sm bg-primary text-white position-fixed left-0 w-100 home-footer">
          <div className="container">
            <div className="row justify-content-between">
              {/* <div className="col-3 d-flex align-items-center justify-content-center p-0">
                <a className="text-white text-decoration-none" href="/profile">
                  <img src={img10} alt=" My Learning" width="15" height="15" />
                  <span>My Profile</span>
                </a>
              </div> */}
              <div className="col-3 d-flex align-items-center justify-content-center p-0">
                <a className="text-white text-decoration-none" href="/calendar">
                  <img src={img9} alt=" My Learning" width="15" height="15" />
                  <span>Calendar</span>
                </a>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-center p-0">
                <a
                  href="/mylearning"
                  className="text-white text-decoration-none"
                >
                  <img width="15" height="15" src={img3} />
                  <span>My Learning</span>
                </a>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-center p-0">
                <a onClick={async () => await handleLogin("logout")}>
                  <img width="15" height="15" src={img4} />
                  <span>Logout</span>
                </a>
              </div>

            </div>
          </div>
        </div>
      )}
      {showNavbar &&
        !(window.location?.pathname?.split("/").length > 5) &&
        window.location?.pathname?.split("/")[1] !== "forgot-password" && (
          <div className="footer-sm bg-primary text-white position-fixed left-0 w-100 home-footer">
            <div className="container">
              <div className="d-flex flex-wrap justify-content-evenly">
                <div className="p-0">
                  <a
                    onClick={() => {
                      setShowSearch(!showSearch);
                      document.body.className += " stickysearch";
                    }}
                  >
                    <img width="15" height="15" src={img2} />
                    <span>Search</span>
                  </a>
                </div>
                <div className="p-0">
                  <a
                    onClick={() => {
                      navigate("/login");
                      setShowNavbar(false);
                    }}
                    title="Login"
                    className="text-white text-decoration-none"
                  >
                    <img width="15" height="15" src={img6} />
                    <span>Login</span>
                  </a>
                </div>
                <div className="p-0">
                  <a
                    onClick={() => {
                      navigate("/signup");
                      setShowNavbar(false);
                    }}
                    title="Sign Up"
                    className="text-white text-decoration-none"
                  >
                    <img width="15" height="15" src={img7} />
                    <span>Sign Up</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

    </>
  );
}
